
import { Component, Prop } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';
import { getComponent, sleep } from '@/utils/helpers';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { mixins } from 'vue-class-component';
import { TASQ_WAITING_ON_DAYS_LEFT, TASQ_WAITING_ON_OVERDUE } from '@/lib/constants';

@Component({
  components: {
    TasqMiniView: () => getComponent('tasqs/TasqMiniView'),
  },
})
export default class TasqItem extends mixins(GenericMixin) {
  @Prop({ type: String, required: false }) tasqId?: string;

  @Prop({ type: Object, required: false }) tasqObject?: TasqJob;

  @Prop({ type: String, required: false }) tasqWellName?: string;

  @Prop({ type: Boolean, required: false }) tasqCompleted?: string;

  @Prop({ type: Boolean, required: false }) tasqProducing?: string;

  @Prop({ type: String, required: false, default: 'tasq' }) viewLevel?: string;

  @Prop({ type: Boolean, required: false }) isPadLevelPage?: boolean;

  @Prop({ type: Boolean, required: false }) isWellLevelPage?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  setFixHeight?: boolean;

  @Prop({ type: Boolean, required: false }) enableMiniView?: boolean;

  hoverTasqItem = false;

  TASQ_WAITING_ON_DAYS_LEFT = TASQ_WAITING_ON_DAYS_LEFT;

  TASQ_WAITING_ON_OVERDUE = TASQ_WAITING_ON_OVERDUE;

  get additionalClasses() {
    let cssClasses = '';
    if (
      (this.isTasqActive && this.routeQueryType !== 'wellview'
      && this.showEpandedView && !this.isPadLevelPage) || this.didCheckTasq
    ) {
      cssClasses = cssClasses.concat(' ', 'border-tasqBase100 bg-primary1100');
    }

    if (this.tasq.snoozed && !this.tasq.isSnoozedForToday) {
      if (this.currentDate >= this.waitingOnEndDate) {
        cssClasses = cssClasses.concat(' ', 'bg-tasqNeutral1600 hover:border-accentError200');
      } else {
        cssClasses = cssClasses.concat(' ', 'bg-tasqNeutral1500 hover:border-accentWarning200');
      }
    } else {
      cssClasses = cssClasses.concat(' ', 'hover:border-tasqNeutral50');
    }

    return cssClasses;
  }

  get user() {
    return workflowModule.user;
  }

  get todaysActions() {
    if (Object.keys(this.tasq.actions).length === 0) {
      return 0;
    }
    const actionObject = this.tasq.actions;
    let today = new Date();
    today = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
    let count = 0;
    Object.keys(actionObject).forEach((property) => {
      const actionsDate = new Date(property);
      actionsDate.setDate(actionsDate.getUTCDate());
      if (today.toLocaleDateString() === actionsDate.toLocaleDateString()) {
        count = actionObject[property];
      }
    });
    return count;
  }

  get tasqViewsCount() {
    const viewsObject = this.tasq.views;
    let totalViews: number = 0;
    if (Object.keys(viewsObject).length === 0) {
      return totalViews;
    }
    Object.values(viewsObject).forEach((value: any) => {
      totalViews += value;
    });
    return totalViews;
  }

  get hasLoggedInUserViewedTasq() {
    let alreadyViewed = false;
    Object.keys(this.tasq.views).forEach((property) => {
      if (property === this.user.username) {
        alreadyViewed = true;
      }
    });
    return alreadyViewed;
  }

  get isLoadingTasqResonses() {
    return tasqsListModule.isLoadingTasqResponses;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  get tasq(): any | undefined {
    const currentRouteName: any = this.$route.name;
    if (this.tasqObject && this.tasqObject.wellName && currentRouteName === 'Tasqs') {
      return this.tasqObject;
    }
    if (this.tasqId === null) {
      return undefined;
    }
    return tasqsListModule.tasqById(this.tasqId!);
  }

  get routeQueryType() {
    return this.$route.query.type;
  }

  get isTasqActive(): boolean {
    return this.tasq?.id === this.activeTasq?.id;
  }

  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (this.$route.query.type === 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    if (
      tasqsListModule.activeTasq != null
      && this.$route.params.id != null
      && this.$route.query.type === 'producing'
      && tasqsListModule.activeTasq.level.toLowerCase() === 'pad'
    ) {
      return tasqsListModule.activeTasq;
    }

    if (assetsModule.activeTasq === undefined) {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  get didCheckTasq() {
    if (this.tasq != null) {
      return tasqsListModule.checkedTasqs.includes(this.tasq!.id);
    }
    return false;
  }

  get checkedTasqList() {
    return tasqsListModule.checkedTasqs || [];
  }

  get waitingOnStartDate() {
    const date: any = new Date(this.tasq?.waitingOnStartDate);
    date.setHours(0, 0, 0);
    return date;
  }

  get waitingOnEndDate() {
    const date: any = new Date(this.tasq?.waitingOnEndDate);
    date.setHours(0, 0, 0);
    return date;
  }

  get waitingOnEndDateWithBuffer() {
    const date: any = new Date(this.tasq?.waitingOnEndDate);
    date.setHours(0, 0, 0);
    date.setDate(date.getDate() + 3);
    return date;
  }

  get currentDate() {
    const date: any = new Date();
    date.setHours(0, 0, 0);
    return date;
  }

  get percentageForWaitingOn() {
    const oneDay = 24 * 60 * 60 * 1000;
    if (this.tasq.waitingOnStartDate && this.tasq.waitingOnEndDate) {
      const differenceStartToCurrent = Math.ceil(
        (this.currentDate - this.waitingOnStartDate) / oneDay,
      );
      if (this.currentDate < this.waitingOnStartDate || differenceStartToCurrent < 1) {
        return 0;
      }
      const differenceStartToEnd = Math.ceil(
        Math.abs(this.waitingOnEndDate - this.waitingOnStartDate) / oneDay,
      ) + 1;
      const result = (differenceStartToCurrent / differenceStartToEnd) * 100;
      if (result > 100) {
        return 100;
      }
      return result;
    }
    return 0;
  }

  get progressBarToolTipText() {
    const oneDay = 24 * 60 * 60 * 1000;
    let text = '';
    if (this.currentDate >= this.waitingOnEndDate) {
      text = 'Overdue';
    } else if (this.waitingOnStartDate > this.currentDate) {
      const difference = Math.round(Math.abs((this.waitingOnStartDate - this.currentDate) / oneDay));
      text = `Starts in ${difference} days`;
      if (difference === 1) {
        text = `Starts in ${difference} day`;
      }
    } else if (this.currentDate > this.waitingOnStartDate && this.currentDate < this.waitingOnEndDate) {
      const difference = Math.round(Math.abs((this.waitingOnEndDate - this.currentDate) / oneDay));
      text = `${difference} days left`;
      if (difference === 1) {
        text = `${difference} day left`;
      }
    }
    return text;
  }

  get waitingOnReason() {
    if (this.tasq.waitUntilReason) {
      return this.tasq.waitUntilReason.split('-Remarks:')[0];
    }
    return '';
  }

  get isScheduledLater() {
    return false;
  }

  get fullName() {
    return getNameByEmail(this.tasq?.username);
  }

  getOverridenRealTimeLabel(label) {
    if (label.toLowerCase() === 'rtd') {
      return 'Deferment';
    }
    if (label.toLowerCase() === 'hiline') {
      return 'Line Pressure';
    }
    return label;
  }

  checkTasq() {
    if (this.tasq != null && this.tasq.id) {
      tasqsListModule.setIsBatchResponding(false);
      tasqsListModule.checkTasq(this.tasq!.id);
      tasqsListModule.setBatchResponseEnabled();
    }
    if (this.checkedTasqList && this.checkedTasqList.length === 1) {
      const currentName: any = this.$route.name;
      this.$router.push({ name: currentName });
      tasqsListModule.setIsLoadingTasqResponses(false);
      tasqsListModule.setActiveTasq('');
      tasqsListModule.setIsEditing(true);
    } else if (this.checkedTasqList.length === 0) {
      this.closeEditToolbar();
    }
  }

  closeEditToolbar() {
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
    tasqsListModule.setActiveTasq('');
    tasqsListModule.resetCheckedTasqs();
  }

  async goToTasqPage(type) {
    if (this.isEditing) {
      this.checkTasq();
      return;
    }

    tasqsListModule.setTasqDataLoadingFeedback(true);
    await sleep(500);
    const currentRouteName: any = this.$route.name;
    if (type === 'producing') {
      this.$router.push({
        name: currentRouteName,
        params: {
          id: this.tasq?.wellName || '',
        },
        query: { type: 'producing', view: this.viewLevel },
      });
      tasqsListModule.setIsEditing(false);
      assetsModule.setActiveProducingTasq('');
      tasqsListModule.setActiveTasq(this.tasq?.id || '');
    } else {
      this.$router.push({
        name: currentRouteName,
        params: {
          id: this.tasq?.id || '',
        },
        query: { type: 'id', view: this.viewLevel },
      });
      tasqsListModule.setIsEditing(false);
      assetsModule.setActiveProducingTasq('');
      tasqsListModule.setActiveTasqExplicit(this.tasq);
    }
  }
}
